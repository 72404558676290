import styles from './styles.module.css';
import {
	ContentfulCollapsiblePanelSection,
	Maybe,
	ContentfulResearchDevelopmentPage,
	ContentfulReportResource,
	ContentfulPublicationResource,
	ContentfulProjectResource,
} from 'src/graphql-types';
import {
	PrivateCollapsiblePanelSection,
	defaultResearchAndDevelopmentPageSections,
	ContentfulTypes,
	BackEndContentTypes,
	RelevantPages,
	FilterContentTypeItems,
	AccessibleByValues,
	getHrImageUrl,
} from 'src/utils/common';
import React from 'react';
import { Helmet } from 'react-helmet';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { MainImage } from 'src/components/mainImage';
import { SectionContainer, SectionContainerTitle } from 'src/components/sectionContainer';
import { LinkHelper } from 'src/utils/link-helper';
import {
	defaultRichTextOptions,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import { MultiCarousel } from 'src/components/multiCarousel';
import { curatedTitle } from 'src/utils/curated-title';
import { ContentThumbnailCard } from 'src/components/contentThumbnailCard';
import {
	SectionWithSearchResourceTemplate,
	mapResources,
} from 'src/templates/sectionWithSearchResourceTemplate/sectionWithSearchTemplate';
import { CollapsiblePanelCollection } from '../collapsiblePanel/collapsiblePanelCollection';

interface ModifiedContentfulResearchDevelopmentPage
	extends Omit<ContentfulResearchDevelopmentPage, 'lepResearchProgramCollapsiblePanels'> {
	lepResearchProgramCollapsiblePanels?: Maybe<
		ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection
	>[];
}

interface PageContext {
	pageContext: {
		data: ModifiedContentfulResearchDevelopmentPage;
		latestRelatedResearchProjects: ContentfulProjectResource[];
		latestRelatedReportResource: ContentfulReportResource[];
	};
}

export default class ResearchDevelopmentPage extends React.Component<PageContext> {
	render() {
		const {
			data,
			latestRelatedResearchProjects,
			latestRelatedReportResource,
		} = this.props?.pageContext;

		const addLayout = (children: JSX.Element) => {
			return <DefaultLayoutTemplate>{children}</DefaultLayoutTemplate>;
		};

		if (!data) {
			console.error('No page data for this ResearchDevelopmentPage');
			return addLayout(
				<SectionContainer withBorderTop>
					<p className={styles.message}>This page is under maintenance. Please try again later</p>
				</SectionContainer>
			);
		}

		const {
			contentful_id,
			title,
			subtitle,
			titleBackgroundImage,
			overviewText,
			overviewResources,
			lepResearchProgramCollapsiblePanels,
			researchProjects,
			researchReports,
			overviewHeading,
			lepResearchProgramHeading,
			researchProjectsHeading,
			researchReportsHeading,
		} = data;

		// these booleans are used to check whether we render the section or not.
		// Beware of the fact that we are not checking for private resources.
		// This is technically wrong, we should address the possibility that the sections may only have private resources and no public resources. However, this possibility is remote, and addressing it requires time.
		const hasResearchProjects =
			[...(researchProjects || []), ...(latestRelatedResearchProjects || [])].length > 0;
		const hasResearchReports =
			[...(researchReports || []), ...(latestRelatedReportResource || [])].length > 0;

		return addLayout(
			<div>
				<Helmet>
					<title>{curatedTitle(title)}</title>
				</Helmet>
				<MainImage
					backgroundImageSrc={getHrImageUrl(titleBackgroundImage?.file?.url)}
					header={title}
					paragraph={subtitle}
				/>
				{/* Overview */}
				<SectionContainer isGrey style={{ paddingTop: '70px' }}>
					{overviewText && (
						<SectionContainerTitle
							text={overviewHeading || defaultResearchAndDevelopmentPageSections.overview}
							id={LinkHelper.parseInternalLink(
								overviewHeading || defaultResearchAndDevelopmentPageSections.overview
							)}
						/>
					)}
					{getRichText(overviewText, defaultRichTextOptions)}
				</SectionContainer>
				{/* Overview Resources */}
				<SectionContainer isGrey>
					{overviewResources && (
						<MultiCarousel>
							{overviewResources
								.filter((resource) => resource)
								.map((resource) => {
									const isPublic =
										!resource?.accessibleBy ||
										(resource?.accessibleBy?.length &&
											resource?.accessibleBy.find((ab) => ab === AccessibleByValues.Public));
									const downloadLink = isPublic
										? (resource as Maybe<ContentfulReportResource>)?.reportFile?.file?.url ||
										  (resource as Maybe<ContentfulPublicationResource>)?.publicationFile?.file?.url
										: undefined;
									const linkTo = (resource as Maybe<ContentfulPublicationResource>)
										?.externalPublicationUrl
										? undefined
										: LinkHelper.getLinkOfContentType(
												resource?.internal?.type as ContentfulTypes | BackEndContentTypes,
												resource?.contentful_id
										  );
									return (
										<ContentThumbnailCard
											key={resource?.contentful_id || ''}
											className={styles.thumbnailCardInCarousel}
											imageSrc={resource?.thumbnailLink?.thumbnailImage?.fluid?.src}
											linkTo={linkTo}
											externalLink={
												(resource as Maybe<ContentfulPublicationResource>)?.externalPublicationUrl
											}
											downloadLink={downloadLink}
										>
											{resource?.thumbnailLink?.title}
										</ContentThumbnailCard>
									);
								})}
						</MultiCarousel>
					)}
				</SectionContainer>
				{/* Research Projects */}
				{hasResearchProjects && (
					<SectionWithSearchResourceTemplate
						featuredResources={mapResources(researchProjects)}
						publicRelevantResources={mapResources(latestRelatedResearchProjects)}
						page={RelevantPages.Research}
						resourceTypes={[FilterContentTypeItems.Project]}
						title={
							researchProjectsHeading || defaultResearchAndDevelopmentPageSections.researchProjects
						}
						titleId={LinkHelper.parseInternalLink(
							researchProjectsHeading || defaultResearchAndDevelopmentPageSections.researchProjects
						)}
					/>
				)}
				{/* LEP Research Program */}
				<SectionContainer isGrey>
					<SectionContainerTitle
						text={
							lepResearchProgramHeading ||
							defaultResearchAndDevelopmentPageSections.lepResearchProgram
						}
						id={LinkHelper.parseInternalLink(
							lepResearchProgramHeading ||
								defaultResearchAndDevelopmentPageSections.lepResearchProgram
						)}
					/>
					<CollapsiblePanelCollection
						parentId={LinkHelper.parseInternalLink(
							lepResearchProgramHeading ||
								defaultResearchAndDevelopmentPageSections.lepResearchProgram
						)}
						panelsCollection={lepResearchProgramCollapsiblePanels}
					/>
				</SectionContainer>
				{/* Research Reports */}
				{hasResearchReports && (
					<SectionWithSearchResourceTemplate
						featuredResources={mapResources(researchReports)}
						publicRelevantResources={mapResources(latestRelatedReportResource)}
						page={RelevantPages.Research}
						resourceTypes={[FilterContentTypeItems.Report]}
						title={researchReportsHeading || defaultResearchAndDevelopmentPageSections.reports}
						titleId={LinkHelper.parseInternalLink(
							researchReportsHeading || defaultResearchAndDevelopmentPageSections.reports
						)}
					/>
				)}
			</div>
		);
	}
}
